import { bionicLP, forbesLP, moneyLP, msmLP, uswitchLP } from '@/content/split-partner-content'
import { BIONIC, FORBES, MONEY, MSM, USW } from '@/helpers/constants/partner-names'

export default function getSplitLpContent (partnerName) {
  switch (partnerName) {
    case BIONIC :
      return bionicLP

    case MONEY :
      return moneyLP

    case MSM :
      return msmLP

    case FORBES :
      return forbesLP  

    case USW :
      return uswitchLP

    default:
      return null
  }
}
