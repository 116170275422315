import { BIONIC, MONEY, MSM, USW, FORBES } from '@/helpers/constants/partner-names'
import { LOAN_INPUT_QUESTION } from '@/helpers/constants/loan-input'

export const bionicLP = {
  title: 'Let’s get started on your business loan comparison',
  titleLineBreak: 'with a couple of quick questions.',
  loanInputQuestion: 'How much are you looking to borrow?',
  partnerName: BIONIC
}

export const moneyLP = {
  title: 'Great, let’s fine-tune your loan options',
  titleLineBreak: 'with a couple of quick questions.',
  loanInputQuestion: LOAN_INPUT_QUESTION,
  partnerName: MONEY
}

export const msmLP = {
  title: 'Great, let’s fine-tune your loan options',
  titleLineBreak: 'with a couple of quick questions.',
  loanInputQuestion: LOAN_INPUT_QUESTION,
  partnerName: MSM
}

export const uswitchLP = {
  title: 'Let’s get started on your business loan comparison',
  titleLineBreak: 'with a couple of quick questions.',
  loanInputQuestion: LOAN_INPUT_QUESTION,
  partnerName: USW
}

export const forbesLP = {
  title: 'Great, let’s fine-tune your loan options',
  titleLineBreak: 'with a couple of quick questions.',
  loanInputQuestion: LOAN_INPUT_QUESTION,
  partnerName: FORBES
}
