<template>
  <div class="page">
    <component 
      :is="currentLP" 
      :split-component-content="splitComponentContent"
    />
  </div>
</template>

<script>
import IsInViewport from '@mic/frontend/mixins/IsInViewport'
import journeyTypeCheck from '@/helpers/journeys/journeyTypeCheck'
import checkComponentName from '@/helpers/components/check-component-name'
import getSplitLpContent from '@/helpers/content/split-lp-content'

export default {
  name: 'Home',
  components: {
    HomepageHeroSplit: () => import('@/components/home/HomepageHeroSplit'),
    DefaultHome: () => import('@/components/home/DefaultHome')
  },

  mixins: [IsInViewport],

  data () {
    return {
      pageName: 'business-finance',
      currentLP: 'DefaultHome',
      splitComponentContent: null
    }
  },
  mounted () {
    // checking for split test with e.g ?journey=modal
    // const journeyNumber = getUrlParams(JOURNEY_PARAM_NAME)
    const journeyType = journeyTypeCheck()
    // component decision
    this.currentLP = checkComponentName(journeyType)
    // content decision
    this.splitComponentContent = getSplitLpContent(journeyType)
    // setting local storage path e.g /asset (asset) for getMultiLeadSourceID 
    const pathArray = window.location.pathname.split('/')
    const currentPath = pathArray[1]
    localStorage.setItem('currentPath', currentPath)
  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: #F8F8F8
}

</style>
