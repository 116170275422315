import { DEFAULT_HOME, HOMEPAGE_HERO_SPLIT } from '@/helpers/constants/component-names'
import { BIONIC, FORBES, MONEY, MSM, USW } from '@/helpers/constants/partner-names'

export default function (name) {
  switch (name) {
    case BIONIC :
      return HOMEPAGE_HERO_SPLIT

    case MONEY :
      return HOMEPAGE_HERO_SPLIT

    case MSM :
      return HOMEPAGE_HERO_SPLIT

    case FORBES :
      return HOMEPAGE_HERO_SPLIT

    case USW :
      return HOMEPAGE_HERO_SPLIT

    default:
      return DEFAULT_HOME
  }
}
